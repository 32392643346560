import React, { Component } from "react";
import ConversationList from "../ConversationList";
import MessageList from "../MessageList";
import "./Messenger.css";
import axios from "axios";
import Api from "../../customlib/Api";
import Modal from "react-bootstrap/Modal";
import Global from "../../customlib/Global";
import {Drawer, Fab, Paper, Slide, Snackbar} from '@mui/material';
import Handover from "../Handover";
import ContactsDashboard from "../ContactsDashboard";
import ContactLeadsReport from "../ContactLeadsReport";
import SalesResponseTimeReport from "../SalesResponseTimeReport";
import ContactsSupplierDashboard from "../ContactsSupplierDashboard";
import ContactsCancelDashboard from "../ContactsCancelDashboard";
// import checkInTimeMp3File from "../../sound/check_in_time.mp3";
import { Campaign, CatchingPokemon, CloudSync, Fireplace, PartyMode, Speaker, SupportAgent, TrendingUp, Update } from "@mui/icons-material";
import DailyFollowUp from "../DailyFollowUp";
import moment from "moment";
import DataPending from "../DataPending";
import GeneralDialog from "./GeneralDialog";
import NotifyFuSalesOnline from "../FollowUp123/NotifyFuSalesOnline";

class Messenger extends Component {
  state = {
    conversations: [],
    messages: [],
    currentConversation: {
      fromNumber: "",
      fromName: "",
      index: 0,
      contactId: 0,
      toNumber: 0,
      toName: "",
      unreadCounter: 0,
      assignToUser: 0,
      assignToUserName: ""
    },
    showSettingDialog: false,
    showLogoutDialog: false,
    bottom: false,
    isLoadingContact: false,
    drawerMode: "",
    isSearching: false,
    csHelpCounter: 0,
    isCsHelp: false,
    conversationViewMode: "ALL_CONTACTS",//ALL_CONTACTS, SEARCH_CONTACTS, CS_HELP
    filterLabel: "",
    listSlowResponse: [],
    listJustRead: [],
    listUnfinishedChat: [],
    listRunningCheckPoint: [],
    runningCheckPointIndex: 0,
    obTimeoutCheckPoint: null,
    obTimeoutButtonCheckPoint: null,
    showButtonCheckPoint: false,
    // checkInTimeAudio: new Audio(checkInTimeMp3File),
    errorMessage: "",
    listCustomerWajibBayar: [],
    filterHasilFu: "",
    listChatNggantung: [],
    firstLoadingConversation: true,
    unreadConversationLonly: false,
    showDailyFu: false,
    toggleCrossedLeads: false,
    isUserAutoleadsOn: false,
    appIsUpdated: true,
    newVersion: "",
    generalDialogSetting: {isOpen: false, title: "", content: "", positiveButtonText: "OK", negativeButtonText: "Close", positiveButtonCallback: ()=>{}, showNegativeButton: false},
    screenWidth: 0
  };

  //drawerMode : HANDOVER, CONTACTS

  constructor(){
    super();

    // Global.getSocket().on("newContactAssigned", data => {
    //     console.log("NEW_CONTACT_ASSIGNED", data);

    //     let check = [];

    //     try{
    //       check = data.filter((newContact) => newContact.assignToUser===Global.get().user.id);
    //     }catch(err){

    //     }

    //     if(check.length > 0){
    //       // if(Global.get().user.id === data.assignToUser){
    //         this.handleReloadConversations(false);
    //         console.log("NEW_CONTACT_FOUND");
    //       // }
    //     }

        
    // });

    // Global.getSocket().on("forceReloadContact", data => {
    //     // console.log("FORCE_RELOAD_CONTACT", data);
    //     // console.log("GLOBALVALUE", Global.get().user.id, " ", data.assignToUser);

    //     let check = [];

    //     try{
    //       check = data.filter((forceReload) => forceReload.userId===Global.get().user.id);
    //     }catch(err){

    //     }

    //     if(check.length > 0){
    //       // if(Global.get().user.id === data.userId){
    //         this.handleReloadConversations(false);
    //         console.log("FORCED_RELOAD_CONTACT");
    //       // }
    //     }
        
    // });

    // if(Global.getCheckPoint() !== null && Global.getCheckPoint().isNightShift === true){
    //   this.getListRunningCheckpoint();
    // }

  }

  // runCheckPoint = () => {
  //   var checkPointData = Global.getCheckPoint();
  //   var rules = this.state.listRunningCheckPoint;

  //   let buttonTimeout = 5 * 60 * 1000;//5 minutes to wait before check point button dissmissed

  //   if(checkPointData.isNightShift){

  //     // var index = 0;

  //     for(var index=0; index<rules.length;index++){
  //       var element = rules[index];
  //       if(element.isChecked === false && element.isMissed !== true){
          
  //         let obTimeout = setTimeout(() => {

  //           this.setState({showButtonCheckPoint: true});
            
  //           let obTimeoutButtonCheckPoint = setTimeout(()=>{
  //             this.setState({showButtonCheckPoint: false});

  //             this.assignCheckPoint(false);

  //             // this.runCheckPoint(false);
  //           }, buttonTimeout);
            
  //           this.setState({obTimeoutButtonCheckPoint: obTimeoutButtonCheckPoint});
  //           console.log("Muncul setelah jeda waktu", this.state.runningCheckPointIndex, this.state.listRunningCheckPoint[this.state.runningCheckPointIndex]);
  //           try{
  //             this.state.checkInTimeAudio.play();
  //           }catch(err){
  //             console.log("PLAYERROR", err);
  //           }
          
  //         }, element.period*60*1000);

  //         this.setState({runningCheckPointIndex: index, obTimeoutCheckPoint: obTimeout});
  //         break;
  //       }
  //     }

  //   }

  // }

  // assignCheckPoint = (isAssignedByUser) => {
  //   clearTimeout(this.state.obTimeoutCheckPoint);
  //   clearTimeout(this.state.obTimeoutButtonCheckPoint);

  //   const params = {
  //     checkPointId: this.state.listRunningCheckPoint[this.state.runningCheckPointIndex]._id,
  //     isMissed: !isAssignedByUser
  //   };
    
  //   console.log("ASSIGN_CP_PARAMS", params);

  //   axios
  //   .post(Api.updateCheckpoint(), params, Api.getRequestHeader())
  //   .then((response) => {

  //     console.log("ASSIGN_CP_RESPONSE", response.data);

  //     var data = response.data;

  //     if(data.success === true){
  //       this.setState({showButtonCheckPoint: false}, ()=>{
  //         this.getListRunningCheckpoint();
  //       });
        
  //     }else{

  //     }

  //   })
  //   .catch((error) => {
  //       console.error("There was an error!", error);
  //   });

  // }

  checkCustomerFu = (callbackWhenHasCustomerFu, callbackWhenHasNoCustomerFu) => {
    axios
    .get(Api.isHasCustomerFu(Global.getAppProfile()._id), Api.getRequestHeader())
    .then((response) => {

      console.log("ASSIGN_CP_RESPONSE", response.data);

      var responseData = response.data;

      if(responseData.data.isHasCustomerFu === false){
        callbackWhenHasNoCustomerFu(responseData.data);
      }else{
        callbackWhenHasCustomerFu(responseData.data);
      }

    })
    .catch((error) => {
        console.error("There was an error!", error);
    });

  }

  /**
   * settings value
   * {title, content, positiveButtonText, negativeButtonText, positiveButtonCallback}
   * @param {settings} settings 
   */
  handleShowGeneralDialog = (title, content, positiveButtonText, negativeButtonText, positiveButtonCallback, showNegativeButton) => {

    const settings = {...this.state.generalDialogSetting};
    settings.isOpen = true;
    settings.title = title;
    settings.content = content;
    settings.positiveButtonText = positiveButtonText;
    settings.negativeButtonText = negativeButtonText;
    settings.positiveButtonCallback = positiveButtonCallback;
    settings.showNegativeButton = showNegativeButton ?? false;
    settings.onClose = this.handleCloseGeneralDialog
    this.setState({generalDialogSetting: settings});
  }

  handleCloseGeneralDialog = () => {
    const settings = {...this.state.generalDialogSetting};
    settings.isOpen = false;
    settings.title = "";
    settings.content = "";
    settings.positiveButtonText = "";
    settings.negativeButtonText = "";
    settings.positiveButtonCallback = null;
    settings.showNegativeButton = false;
    settings.onClose = this.handleCloseGeneralDialog
    this.setState({generalDialogSetting: settings});
  }

  // getListRunningCheckpoint = () => {
  //   const params = {
  //     appProfileId: Global.getAppProfile()._id, 
  //     userId: Global.get().user.id
  //   };
    
  //   axios
  //   .post(Api.listRunningCheckpoint(), params, Api.getRequestHeader())
  //   .then((response) => {
  //     this.setState({listRunningCheckPoint: response.data.data},()=>{
  //       console.log("running check point", this.state.listRunningCheckPoint);
  //       this.runCheckPoint();
  //     });
  //   })
  //   .catch((error) => {
  //       console.error("There was an error!", error);
  //   });
  // }

  getLatestAppVersionInfo = () => {
    const params = {};
    
    axios
    .get(Api.getLatestVersionInfo(), Api.getRequestHeaderNoAuth())
    .then((response) => {
      
      let version = response.data.data;
      console.log("APP_VERSION", version);

      if(version.version !== Global.appVersion()){
        this.setState({appIsUpdated: false, newVersion: version.version});
      }else{
        this.setState({appIsUpdated: true, newVersion: version.version});

      }

    })
    .catch((error) => {
        console.error("There was an error!", error);
    });
  }

  handleResize = () => {
    this.setState({ screenWidth: window.innerWidth }, () => {
      console.log("HANDLE_RESIZE", this.state.screenWidth);
    });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    localStorage.setItem("unmounted", "yes");
  }

  componentDidMount() {
    // this.getMessageTemplate();
    // this.getConversations(false);
    // this.getUnsolvedCount();

    // if(Global.get().admin){
    //   this.getListSlowResponseMessage();
    // }
    window.addEventListener('resize', this.handleResize);
    this.verifySession();
    // this.state.checkInTimeAudio.play();
    this.setState({screenWidth: window.innerWidth},() => {
      if(Global.isCustomerService() === true | Global.isCustomerServiceSpv() === true){
        this.setState({conversationViewMode: "CS_HELP"}, () => {
          this.getMessageTemplate();
          this.getConversations(false, "DID_MOUNT_CS");
          this.getUnsolvedCount();
        });
      }else{
        this.getMessageTemplate();
        this.getConversations(false, "DID_MOUNT");
        // this.getUnsolvedCount();
      }
      this.getListSalesOnline();
  
      setInterval(() => {
        this.getLatestAppVersionInfo();
      }, 60*5*1000);
    });
    
    // setTimeout(() => {
    //   // if(Global.get().admin){
    //     this.getListSlowResponseMessage();
    //     this.getListJustReadMessage();
    //     // }
    
    //     // this.getListUnfinishedChat();
    //     // this.getListCustomerWajibBayar();
    //     // this.getListChatNggantung();

    //     // console.log("ISSALESDATAPENDING : ", Global.isSalesDataPending());

    // }, 7000);

    // if(Global.isSales() === true | Global.isTeleUser()){
    //   this.getListIncompleteTraining();
    // }

  }

  openTeleChatByRef = () => {

    let refData = localStorage.getItem("ref");
    console.log("OPENTELECHAT_1", refData);

    if(refData !== null && refData !== undefined && refData.length > 0){
      let parsedRefData = JSON.parse(refData);

      if(Global.get().user.id !== parsedRefData.salesId){
        this.handleShowSnackbar(
          <>
            {parsedRefData.waNumber} bukan customer anda!
          </>);
        return;
      }

      console.log("OPENTELECHAT_2", parsedRefData);

      // let conversation = this.state.conversations.filter((conv) => conv.fromNumber===parsedRefData.waNumber);

      // if(conversation !== null && conversation.length > 0){
      //   //just open chat room
      //   this.handleConversationSelected(conversation[0]);
      // }else{
        //getcontact then import it into contact list and then open teh chat room

        const params = {
          salesId: parsedRefData.salesId, 
          waNumber: parsedRefData.waNumber, 
          customerId: parsedRefData.customerId,
          sendFrom: parsedRefData.sendFrom === undefined ? "" : parsedRefData.sendFrom,
          message: parsedRefData.message === undefined ? "" : parsedRefData.message,
          appProfileId: Global.getAppProfile()._id
        };


        console.log("PARAMREMINDER", params);

        axios
        .post(Api.getContactByNumber(), params, Api.getRequestHeader())
        .then((response) => {
          let data = response.data;
          console.log("CONTACTINFOBYNUMBER", data);
          if(data.success === true){
            const listConverstions = [...this.state.conversations];

            var newData =  {
              photo: "https://picsum.photos/seed/picsum/200",
              fromName: `${data.data.fromName}`,
              text: data.data.lastMessage,
              fromNumber: data.data.from,
              contactId: data.data.id,
              toNumber: data.data.to,
              toName: data.data.toName,
              selected: false,
              unreadCounter: data.data.unreadCounter,
              assignToUserName: data.data.assignToUserName,
              csHelpState: data.data.csHelpState,
              csId: data.data.csId,
              csName: data.data.csName,
              csArchived: data.data.csArchived,
              contactLabel: data.data.label,
              keterangan: data.data.keterangan,
              emailRegister: data.data.emailRegister
            };

            listConverstions.splice(0, 0, newData);
            this.setState({conversations: listConverstions}, ()=>{
              localStorage.removeItem("ref");
              this.handleConversationSelected(newData);
            });
          }else{
            this.handleShowSnackbar(
              <>
                {data.message}
              </>
            );
          }
          // this.setState({csHelpCounter: data.unsolvedCount});
        })
        .catch((error) => {
          this.handleShowSnackbar(
            <>
              {error.message}
            </>
          );
          console.error("There was an error!", error);
        });
      // }

    }

  };

  openTeleChat = (parsedRefData) => {

    // let refData = localStorage.getItem("ref");
    // console.log("OPENTELECHAT_1", refData);

    // if(refData !== null && refData !== undefined && refData.length > 0){
      // let parsedRefData = JSON.parse(refData);

      // if(Global.get().user.id !== parsedRefData.salesId){
      //   this.handleShowSnackbar(
      //     <>
      //       {parsedRefData.waNumber} bukan customer anda!
      //     </>);
      //   return;
      // }

      console.log("OPENTELECHAT_2", parsedRefData);

      // let conversation = this.state.conversations.filter((conv) => conv.fromNumber===parsedRefData.waNumber);

      // if(conversation !== null && conversation.length > 0){
      //   //just open chat room
      //   this.handleConversationSelected(conversation[0]);
      // }else{
        //getcontact then import it into contact list and then open teh chat room

        const params = {
          salesId: parsedRefData.salesId, 
          waNumber: parsedRefData.waNumber, 
          customerId: parsedRefData.customerId,
          sendFrom: parsedRefData.sendFrom === undefined ? "" : parsedRefData.sendFrom,
          message: parsedRefData.message === undefined ? "" : parsedRefData.message,
          appProfileId: Global.getAppProfile()._id,
          isTesting: true
        };

        
        console.log("PARAM_OPEN_CHAT", params);
        // return;
        axios
        .post(Api.getContactByNumber(), params, Api.getRequestHeader())
        .then((response) => {
          let data = response.data;
          console.log("CONTACTINFOBYNUMBER", data);
          if(data.success === true){
            const listConverstions = [...this.state.conversations];

            // var newData =  {
            //   photo: "https://picsum.photos/seed/picsum/200",
            //   fromName: `${data.data.fromName}`,
            //   text: data.data.lastMessage,
            //   fromNumber: data.data.from,
            //   contactId: data.data.id,
            //   toNumber: data.data.to,
            //   toName: data.data.toName,
            //   selected: false,
            //   unreadCounter: data.data.unreadCounter,
            //   assignToUserName: data.data.assignToUserName,
            //   csHelpState: data.data.csHelpState,
            //   csId: data.data.csId,
            //   csName: data.data.csName,
            //   csArchived: data.data.csArchived,
            //   contactLabel: data.data.label,
            //   keterangan: data.data.keterangan,
            //   emailRegister: data.data.emailRegister
            // };

            let newData = this.generateItemConversation(data.data);

            listConverstions.splice(0, 0, newData);
            this.setState({conversations: listConverstions, showDailyFu: false}, ()=>{
              localStorage.removeItem("ref");
              this.handleConversationSelected(newData);
            });
          }else{
            this.handleShowSnackbar(
              <>
                {data.message}
              </>
            );
          }
          // this.setState({csHelpCounter: data.unsolvedCount});
        })
        .catch((error) => {
          this.handleShowSnackbar(
            <>
              {error.message}
            </>
          );
          console.error("There was an error!", error);
        });
      // }

    // }

  };

  openChatFromLavender = () => {
    console.log("OPENCHAT", "FFUNCTION CALLED");
    let refData = localStorage.getItem("openChat");
    console.log("OPENCHAT_REFDATA", refData);

    if(Global.isCustomerService() || Global.isCustomerServiceSpv() || Global.get().admin){

    }else{
      return;
    }

    if(refData !== null && refData !== undefined && refData.length > 0){
      console.log("OPENCHAT", "MASUK IF CHEK REFDATA");
      let parsedRefData = JSON.parse(refData);

      // if(Global.get().user.id !== parsedRefData.salesId){
      //   return;
      // }

      console.log("OPENCHAT", parsedRefData);

      // let conversation = this.state.conversations.filter((conv) => conv.fromNumber===parsedRefData.waNumber);

      // if(conversation !== null && conversation.length > 0){
      //   //just open chat room
      //   this.handleConversationSelected(conversation[0]);
      // }else{
        //getcontact then import it into contact list and then open teh chat room

        const params = {
          waNumber: parsedRefData.waNumber
        };

        console.log("PARAMOPENCHAT", params);

        axios
        .post(Api.getContactInfo(), params, Api.getRequestHeader())
        .then((response) => {
          let data = response.data;
          console.log("CONTACTINFOBYNUMBER", data);
          if(data.success === true){
            const listConverstions = [...this.state.conversations];

            var newData = this.generateItemConversation(data.data)

            listConverstions.splice(0, 0, newData);
            this.setState({conversations: listConverstions}, ()=>{
              localStorage.removeItem("openChat");
              this.handleConversationSelected(newData);
            });
          }
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      // }

    }

  };

  openFuChatByFu = () => {

    let refData = localStorage.getItem("fu");

    if(refData !== null && refData !== undefined && refData.length > 0){
      let parsedRefData = JSON.parse(refData);

      if(Global.get().user.id !== parsedRefData.salesId){
        return;
      }

      console.log("OPENFUCHAT", parsedRefData);

      // let conversation = this.state.conversations.filter((conv) => conv.fromNumber===parsedRefData.waNumber);

      // if(conversation !== null && conversation.length > 0){
      //   //just open chat room
      //   this.handleConversationSelected(conversation[0]);
      // }else{
        //getcontact then import it into contact list and then open teh chat room

        const params = {
          waNumber: parsedRefData.waNumber
        };

        console.log("PARAMREMINDER", params);

        axios
        .post(Api.getContactInfo(), params, Api.getRequestHeader())
        .then((response) => {
          let data = response.data;
          console.log("CONTACTINFOBYNUMBER", data);
          if(data.success === true){
            // const listConverstions = [...this.state.conversations];

            var newData =  {
              photo: "https://picsum.photos/seed/picsum/200",
              fromName: `${data.data.fromName}`,
              text: data.data.lastMessage,
              fromNumber: data.data.from,
              contactId: data.data.id,
              toNumber: data.data.to,
              toName: data.data.toName,
              selected: false,
              unreadCounter: data.data.unreadCounter,
              assignToUserName: data.data.assignToUserName,
              csHelpState: data.data.csHelpState,
              csId: data.data.csId,
              csName: data.data.csName,
              csArchived: data.data.csArchived,
              contactLabel: data.data.label,
              keterangan: data.data.keterangan,
              emailRegister: data.data.emailRegister
            };

            this.handleAssignContactToUser(newData);

            // listConverstions.splice(0, 0, newData);
            // this.setState({conversations: listConverstions}, ()=>{
            //   localStorage.removeItem("fu");
            //   this.handleConversationSelected(newData);
            // });
          }
          // this.setState({csHelpCounter: data.unsolvedCount});
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      // }

    }

  };

  handleAssignContactToUser = (contact) => {
    console.log("PARAMASSIGNFU : ", {contactId: contact.contactId, userId: Global.get().user.id});
    
    this.setState({isLoading: true}, () => {

        const params = {
          contactId: contact.contactId, 
          userId: Global.get().user.id
        };

        axios
        .post(Api.assignContactToUser(), params, Api.getRequestHeader())
        .then((response) => {
          const listConverstions = [...this.state.conversations];

          contact.assignToUser = Global.get().user.id;
          contact.assignToUserName = Global.get().user.name;

          listConverstions.splice(0, 0, contact);
          this.setState({conversations: listConverstions}, ()=>{
            localStorage.removeItem("fu");
            this.handleConversationSelected(contact);
          });
        })
        .catch((error) => {
            this.setState({isLoading: false});
            console.error("There was an error!", error);
        });
    });
  }

  clearCurrentConversation = () => {
    this.setState({currentConversation : {
      fromNumber: "",
      fromName: "",
      index: 0,
      contactId: 0,
      toNumber: 0,
      toName: "",
      unreadCounter: 0,
      assignToUser: 0,
      assignToUserName: ""
    }});
  }

  handleUnreadConversationOnlyChange = (isUnreadOnly) => {
    this.setState({unreadConversationLonly: isUnreadOnly}, () => {
      this.getConversations(false, "handleUnreadConversationOnlyChange");
    });
  }

  getConversations = (updateSelection, source) => {
    // axios.get("https://randomuser.me/api/?results=20")
    // console.log("PARAMGETCONV", {userId: Global.get().user.id});

    if(this.state.isLoadingContact){
      return;
    }

    console.log("LOADING CONVERSATION TEST", source);

    this.setState(
      {isLoadingContact: true}, 
      () => {

        const params = {
          userId: Global.get().user.id,
          appProfileId: Global.getAppProfile()._id,
          filterLabel: this.state.filterLabel,
          filterHasilFu: this.state.filterHasilFu,
          hasUnreadMessageOnly: this.state.unreadConversationLonly,
          crossedLeads: this.state.toggleCrossedLeads
        };

        axios
        .post(Api.contactList(), params, Api.getRequestHeader())
        .then((response) => {
          console.log("CONVERSATIONS : ",response.data);
          let data = response.data.data.map((result) => {
            return this.generateItemConversation(result);
          });

          this.setState({ conversations: data, isLoadingContact: false, firstLoadingConversation: false }, () => {
            this.sortConversations();
            if (data.length > 0 && updateSelection) {
              this.handleConversationSelected(data[0]);
            }

            this.openTeleChatByRef();
            this.openFuChatByFu();
            this.openChatFromLavender();

          });
          // this.setState({});
          
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message, isLoadingContact: false });
          console.error("There was an error!", error);
        });
      }
    );
  };

  generateItemConversation = (contactData) => {
    var newData =  {
      _id: contactData._id,
      photo: "https://picsum.photos/seed/picsum/200",
      fromName: `${contactData.fromName}`,
      text: contactData.lastMessage,
      fromNumber: contactData.from,
      contactId: contactData.id,
      toNumber: contactData.to,
      toName: contactData.toName,
      selected: false,
      unreadCounter: contactData.unreadCounter,
      assignToUserName: contactData.assignToUserName,
      assignToUser: contactData.assignToUser,
      firstContactAssignedTo: contactData.firstContactAssignedTo,
      firstContactAssignedToName: contactData.firstContactAssignedToName,
      csHelpState: contactData.csHelpState,
      csId: contactData.csId,
      csName: contactData.csName,
      csArchived: contactData.csArchived,
      contactLabel: contactData.label,
      productCategory: contactData.productCategory,
      cityId: contactData.cityId,
      cityName: contactData.cityName,
      lastFuResult: contactData.lastFuResult !== undefined ? contactData.lastFuResult : "",
      keterangan: contactData.keterangan,
      emailRegister: contactData.emailRegister,
      customerName: contactData.customerName,
      keteranganPending: contactData.keteranganPending,
      keteranganCancel: contactData.keteranganCancel,
      deliveryDate: contactData.deliveryDate,
      lastMessageTime: contactData.lastMessageTime,
      tipeLead: contactData.tipeLead ?? "",
      permasalahan: contactData.permasalahan,
      alasanUmum: contactData.alasanUmum,
      alasanRinci: contactData.alasanRinci,
      crossedPending: contactData.crossedPending ?? false,
      isCustomerRecycle: contactData.isCustomerRecycle ?? false,
      customerRecycledAt: contactData.customerRecycledAt,
      salesOnlineFu: contactData.salesOnlineFu,
      isTeleCustomer: contactData.isTeleCustomer,
      createdAt: contactData.created_at,
      priceRange: contactData.priceRange,
      kategoriKonfirmasi: contactData.kategoriKonfirmasi
    };

    return newData;
  }

  sortConversations = () => {
    const conversations = [...this.state.conversations];

    conversations.sort((a,b) => b.lastMessageTime-a.lastMessageTime);

    this.setState({conversations: conversations});

  }

  handleSavenamaCustomer = (customerName) => {
    let currentConversation = this.state.currentConversation;
    const params = {
      contactNumber: currentConversation.fromNumber,
      customerName: customerName 
    };

    axios
    .post(Api.saveCustomerName(), params, Api.getRequestHeader())
    .then((response) => {
      let data = response.data;
      console.log("SAVE_CUSTOMER_NAME", response.data);
      
      if(data.success === true){

        const conversations = [...this.state.conversations];

        let conversation = this.state.conversations.filter((conv) => conv.fromNumber===currentConversation.fromNumber);
        if(conversation.length > 0){
          conversation[0].customerName = customerName;
        }        

        currentConversation.customerName = customerName;
        this.setState({currentConversation: currentConversation, conversations: conversations});
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });

  }

  handleChangeFilterLabel = (label) => {
    if(this.state.firstLoadingConversation){
      return;
    }
    this.setState({filterLabel: label}, () => {
      this.getConversations(false, "HANDLE_CHANGE_FILTER_LABEL");
    });
  }
  
  handleChangeFilterHasilFu = (hasilFu) => {
    if(this.state.firstLoadingConversation){
      return;
    }
    this.setState({filterHasilFu: hasilFu}, () => {
      this.getConversations(false, "HANDLE_CHANGE_FILTER_HASIL_FU");
    });
  }

  onToggleCrossedLeadsChange = (event) => {
    if(this.state.firstLoadingConversation){
      return;
    }
    this.setState({toggleCrossedLeads: event.target.checked}, () => {
      this.getConversations(false, "HANDLE_CHANGE_CROSSED_LEADS");
    });
  }

  handleReloadConversations = (updateSelection) => {
    this.getConversations(updateSelection, "HANDLE_RELOAD_CONVERSATIONS");
    if (!updateSelection) {
      // console.log("UPDATE SELECTION : ", updateSelection);
      this.handleConversationSelected(this.state.currentConversation);
    }
  };

  handleUpdateUserAutoleadsState = (salesData) => {
    this.setState({isUserAutoleadsOn: salesData.allowReceiveLeads === 1 ? true : false}, () => {
      let snackbarMessage = "Autoleads untuk " + salesData.lavenderSalesName + (salesData.allowReceiveLeads === 1 ? " sudah DIAKTIFKAN" : " sudah DINONAKTIFKAN");
      
      if(salesData.message){
        snackbarMessage = <>
          {snackbarMessage}<br />
          {salesData.message}
        </>;
      }
      this.handleShowSnackbar(snackbarMessage);
    });
  };

  getUnsolvedCount = () => {

    // axios.get("https://randomuser.me/api/?results=20")
    axios
      .post(Api.csHelpUnsolvedCount(), {userId: Global.get().user.id}, Api.getRequestHeader())
      .then((response) => {
        let data = response.data.data;
        this.setState({csHelpCounter: data.unsolvedCount});
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  getMessageTemplate = () => {
    // axios.get("https://randomuser.me/api/?results=20")
    const params = {
      appProfileId: Global.getAppProfile()._id
    };

    axios
      .post(Api.messageTemplate(), params, Api.getRequestHeader())
      .then((response) => {
        console.log(response.data);
        let template = response.data.data;
        if (template) {
          Global.setMessageTemplate(template);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  resetUnreadCounter = (conversationId, createJustReadLog) => {
    var params = {conversationId: conversationId, createJustReadLog: createJustReadLog};
    console.log("PARAMS_RESET_UNREAD", params);
    axios
      .post(Api.resetUnreadCounter(), params, Api.getRequestHeader())
      .then((response) => {
        // console.log("RESETCOUNTER : ", response.data);
        this.setState({});
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  
  verifySession = () => {

    const params = {};

    axios
      .post(Api.verifySession(), params, Api.getRequestHeader())
      .then((response) => {
        console.log("VERIFY_SESSION : ", response);
        
      })
      .catch((error) => {
        this.handleLogout();
        console.error("There was an error!", error);
      });
  };

  markAsRead = (conversation) => {

    const params = {
      fromNumber: conversation.fromNumber
    };

    axios
      .post(Api.markAsread(), params, Api.getRequestHeader())
      .then((response) => {
        console.log("MARKED_AS_READ : ", response.data);
        // this.setState({});
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  handleConversationSelected = (conversation) => {
    console.log("SELECTED : ", conversation);
    // return;

    const reset = this.state.conversations.map((c) => {
      c.selected = false;
      return c;
    });

    this.setState({ conversations: reset });

    const conversations = [...this.state.conversations]; //... = create clone
    const index = conversations.indexOf(conversation);

    // if(index <= 0){
    //   return;
    // }

    conversations[index] = { ...conversation };
    conversations[index].selected = true;
    conversations[index].unreadCounter = 0;

    let fromNumber = conversation.fromNumber;
    let contactId = conversation.contactId;
    let fromName = conversation.fromName;
    let toNumber = conversation.toNumber;
    let toName = conversation.toName;
    let contactLabel = conversation.contactLabel;

    let currentConversation = {
      _id: conversation._id,
      fromNumber: fromNumber,
      index: index,
      contactId: contactId,
      fromName: fromName,
      toNumber: toNumber,
      toName: toName,
      csHelpState: conversation.csHelpState,
      csArchived: conversation.csArchived,
      csId: conversation.csId,
      csName: conversation.csName,
      assignToUser: conversation.assignToUser,
      assignToUserName: conversation.assignToUserName,
      contactLabel: contactLabel,
      productCategory: conversation.productCategory,
      cityId: conversation.cityId,
      deliveryDate: conversation.deliveryDate,
      cityName: conversation.cityName,
      keterangan: conversation.keterangan,
      emailRegister: conversation.emailRegister,
      customerName: conversation.customerName,
      keteranganPending: conversation.keteranganPending,
      keteranganCancel: conversation.keteranganCancel,
      lastMessageTime: conversation.lastMessageTime,
      tipeLead: conversation.tipeLead,
      permasalahan: conversation.permasalahan,
      alasanUmum: conversation.alasanUmum,
      alasanRinci: conversation.alasanRinci,
      crossedPending: conversation.crossedPending ?? false,
      isCustomerRecycle: conversation.isCustomerRecycle ?? false,
      customerRecycledAt: conversation.customerRecycledAt,
      salesOnlineFu: conversation.salesOnlineFu,
      isTeleCustomer: conversation.isTeleCustomer,
      createdAt: conversation.createdAt,
      priceRange: conversation.priceRange,
      kategoriKonfirmasi: conversation.kategoriKonfirmasi
    };

    // let currentConversation = this.generateItemConversation(conversation);

    this.setState({
        conversations: conversations,
        currentConversation: currentConversation,
    });

    this.resetUnreadCounter(currentConversation.contactId, true);
    this.markAsRead(currentConversation);

  };

  handleUpdateConversation = (conversation, newMessageText) => {
    // console.log("UPDATECONVERSATION", conversation, newMessageText);
    const conversations = [...this.state.conversations]; //... = create clone
    const clonedConversation = { ...conversations[conversation.index] };
    conversations[conversation.index] = clonedConversation;
    conversations[conversation.index].text = newMessageText;
    // console.log("UPDATECONVERSATION", conversation.index, conversations[conversation.index].photo);
    this.setState({ conversations: conversations });
  };

  handleUpdateUnreadCounter = (incomingMessage) => {
    // console.log("UPDATEREADCOUNTER : ",incomingMessage);

    if(!Global.get().admin && !Global.isAdminEpUser() && !Global.isCustomerService() && !Global.isCustomerServiceSpv() && !Global.isAdminTeleUser() && incomingMessage.assignToUser !== Global.get().user.id && !Global.isAdminDataCancel()){
      console.log("LOADING CONVERSATION TEST", "REJECT UPDATE UNREAD COUNTER");
      return;
    }

    const conversations = [...this.state.conversations];
    let conversation = conversations.filter((conv) => conv.fromNumber===incomingMessage.from);
    // console.log("NEW MESSAGE FOR : ",conversation);
    if(conversation.length > 0){
        var unread = conversation[0].unreadCounter;
        conversation[0].unreadCounter = unread+1;
        conversation[0].text = incomingMessage.message.type === "TEXT" ? incomingMessage.message.text : incomingMessage.message.caption;
        conversation[0].lastMessageTime = incomingMessage.lastMessageTime;
    }else{

      if(Global.isAdminTeleUser() || Global.isTeleUser() || Global.isTeleCutomer()){
        if(Global.getAppProfile().waNumber === incomingMessage.contact.to && incomingMessage.contact.isTeleCustomer){
          conversations.push(this.generateItemConversation(incomingMessage.contact));
        }
      }else if(Global.isAdminDataCancel()){
        if(Global.getAppProfile().waNumber === incomingMessage.contact.to && !incomingMessage.contact.isTeleCustomer && incomingMessage.contact.label === Global.labelCancel()){
          conversations.push(this.generateItemConversation(incomingMessage.contact));
        }
      }else if(Global.get().admin || Global.isAdminEpUser() || Global.isCustomerService() || Global.isCustomerServiceSpv() || incomingMessage.assignToUser === Global.get().user.id){
        
        // this.getConversations(false, "HANDLE_UPDATE_UNREAD_COUNTER");
        
        let label = incomingMessage.contact.label;
        // if(incomingMessage.contact.from !== "6285728655920"){
        //   return;
        // }
        // if(label === "PENDING" && incomingMessage.assignToUser === Global.get().user.id){
        //   console.log("TESTPENDING_1_deliveryDate_ori", incomingMessage.contact.deliveryDate, label);
        //   let deliveryDate = incomingMessage.contact.deliveryDate === null || incomingMessage.contact.deliveryDate === undefined ? null : moment(incomingMessage.contact.deliveryDate).tz("utc");
        //   console.log("TESTPENDING_2_deliveryDate_COND", deliveryDate);
        //   let now = moment().tz("utc");
        //   console.log("TESTPENDING_3_NOW_UTC", now);
        //   let deltaInSeconds = 0;
        //   if(deliveryDate !== null){
        //     deltaInSeconds = now.diff(deliveryDate, "seconds");
        //     console.log("TESTPENDING_4_DELTA", deltaInSeconds);
        //   }

        //   if(deltaInSeconds > 3600){
        //     return;
        //   }

        // }

        if(Global.getAppProfile().waNumber === incomingMessage.contact.to){
          conversations.push(this.generateItemConversation(incomingMessage.contact));
        }
      }else if(Global.getAppProfile().waNumber === incomingMessage.contact.to && Global.get().user.id === incomingMessage.contact.assignToUser){
        conversations.push(this.generateItemConversation(incomingMessage.contact));
      }
        
    }

    this.setState({ conversations: conversations }, () => {
      this.sortConversations();
    });
  }

  checkIfConversationExists = (fromNumber) => {
    const conversations = [...this.state.conversations];
    let conversation = conversations.filter((conv) => conv.fromNumber===fromNumber);
    // console.log("NEW MESSAGE FOR : ",conversation);
    if(conversation.length > 0){
        return true;
    }else{
        return false;
    }
  }

  handleUpdateCsHelpState = (data) => {
    console.log(Global.get());
    const conversations = [...this.state.conversations];
    let conversation = conversations.filter((conv) => conv.contactId===data.contactId);
    // console.log("NEW MESSAGE FOR : ",conversation);
    if(conversation.length > 0){
      console.log("CONVERSATION FOUND");
        conversation[0].csHelpState = data.helpState;
        conversation[0].csId = data.csId;
        conversation[0].csName = data.csName;
        conversation[0].csArchived = data.archived;

        this.setState({conversations: conversations, currentConversation: data.contactId === this.state.currentConversation.contactId ? conversation[0] : this.state.currentConversation}, () => {
          console.log("LASTCONVSTATE : ", this.state.currentConversation);
          this.getUnsolvedCount();
          // this.props.handleConversationSelected(conversation);
        });

    }
  }

  handleUpdateContactLabelState = (data) => {
    console.log("UPDATE_LABEL_STATE", data);
    const conversations = [...this.state.conversations];
    let conversation = conversations.filter((conv) => conv.contactId===data.id);
    if(conversation.length > 0){
      console.log("CONVERSATION FOUND");
      console.log("masuk", 1);
        conversation[0].contactLabel = data.label;

        if(data.isTeleCustomer !== true && (data.label === Global.labelPaid() | data.label === Global.labelCancel())){
          console.log("masuk", 2);
          this.setState({
            conversations: conversations, 
            currentConversation: data.isTeleCustomer !== true && data.id === this.state.currentConversation.contactId && (data.label === Global.labelPaid() | data.label === Global.labelCancel()) ? 
            {
              fromNumber: "",
              fromName: "",
              index: 0,
              contactId: 0,
              toNumber: 0,
              toName: "",
              unreadCounter: 0
            } : this.state.currentConversation}, () => {
            console.log("LASTCONVSTATE : ", this.state.currentConversation);
            this.getConversations(false, "HANDLE_UPDATE_CONTACT_LABEL_STATE_IF");
            this.getUnsolvedCount();
            // this.props.handleConversationSelected(conversation);
          });
        }else{
          console.log("masuk", 3);
          this.setState({
            conversations: conversations, 
            currentConversation: data.id === this.state.currentConversation.contactId ? 
              conversation[0] : this.state.currentConversation}, () => {
              console.log("LASTCONVSTATE : ", this.state.currentConversation);
              this.getConversations(false, "HANDLE_UPDATE_CONTACT_LABEL_STATE_ELSE");
              this.getUnsolvedCount();
              // this.props.handleConversationSelected(conversation);
              }
          );
        }

    }else{
      
      console.log("masuk", 4);
      if(data.isTeleCustomer !== true && (data.label === Global.labelPaid() | data.label === Global.labelCancel())){
        console.log("masuk", 5);
        this.setState({
          currentConversation: data.isTeleCustomer !== true && data.id === this.state.currentConversation.contactId && (data.label === Global.labelPaid() | data.label === Global.labelCancel()) ? 
          {
            fromNumber: "",
            fromName: "",
            index: 0,
            contactId: 0,
            toNumber: 0,
            toName: "",
            unreadCounter: 0
          } : this.state.currentConversation}, () => {
          console.log("LASTCONVSTATE : ", this.state.currentConversation);
          this.getConversations(false, "HANDLE_UPDATE_CONTACT_LABEL_STATE_IF");
          this.getUnsolvedCount();
          // this.props.handleConversationSelected(conversation);
        });
      }else{
        console.log("masuk", 6);
        
        let currentConversation = JSON.parse(JSON.stringify(this.state.currentConversation));
        // if(currentConversation !== null && currentConversation !== undefined){
          currentConversation.contactLabel = data.label;
          this.setState({
            currentConversation: data.id === this.state.currentConversation.contactId ? 
              currentConversation : this.state.currentConversation
            }, () => {
              console.log("LASTCONVSTATE : ", this.state.currentConversation);
              this.getConversations(false, "HANDLE_UPDATE_CONTACT_LABEL_STATE_ELSE");
              this.getUnsolvedCount();
              // this.props.handleConversationSelected(conversation);
              }
          );
        // }
        
      }
    }
  }
  
  handleSettingClick = () => {
    console.log(Global.get());
  };

  handleCloseSettingDialog = () => {
    this.setState({ showSettingDialog: false });
  };

  handleLogoutClick = () => {
    console.log("CLICKED");
    let callbackHasCustomerFu = (checkCustomerData) => {
      if(checkCustomerData.countCustomer > 0){
        this.handleShowGeneralDialog("Logout", <div>Logout belum bisa dilakukan,<br />Masih ada {checkCustomerData.countCustomer} customer yang belum selesai di FU</div>, "OK", "Tutup", this.handleCloseGeneralDialog);
      }else{
        this.setState({ showLogoutDialog: true });
      }
    }

    let callbackWhenHasNoCustomerFu = (checkCustomerData) => {
      this.setState({ showLogoutDialog: true });
    }

    this.checkCustomerFu(callbackHasCustomerFu, callbackWhenHasNoCustomerFu);
  };

  handleCloseLogoutDialog = () => {
    this.setState({ showLogoutDialog: false, errorMessage: "" });
  };

  handleHandoverClick = () => {
    console.log("CLICKED");
    let callbackHasCustomerFu = (checkCustomerData) => {
      if(checkCustomerData.countCustomer > 0){
        this.handleShowGeneralDialog("Handover", <div>Handover belum bisa dilakukan,<br />Masih ada {checkCustomerData.countCustomer} customer yang belum selesai di FU</div>, "OK", "Tutup", this.handleCloseGeneralDialog);
      }else{
        this.setState({ ...this.state, bottom: true, drawerMode: "HANDOVER" });
      }
    }

    let callbackWhenHasNoCustomerFu = (checkCustomerData) => {
      this.setState({ ...this.state, bottom: true, drawerMode: "HANDOVER" });
    }

    this.checkCustomerFu(callbackHasCustomerFu, callbackWhenHasNoCustomerFu);
    // this.toggleDrawer('bottom', true);
    // this.setState({ ...this.state, bottom: true, drawerMode: "HANDOVER" });
  }

  handleContactsDashboardClick = () => {
    console.log("CLICKED");
    // this.toggleDrawer('bottom', true);
    this.setState({ ...this.state, bottom: true, drawerMode: "CONTACTS" });
  }

  handleLeadsReportClick = () => {
    console.log("CLICKED");
    // this.toggleDrawer('bottom', true);
    this.setState({ ...this.state, bottom: true, drawerMode: "REPORT" });
  }

  handleDataCancelClick = () => {
    console.log("CLICKED");
    // this.toggleDrawer('bottom', true);
    this.setState({ ...this.state, bottom: true, drawerMode: "DATA_CANCEL" });
  }

  handleResponseTimeClick = () => {
    console.log("CLICKED");
    // this.toggleDrawer('bottom', true);
    this.setState({ ...this.state, bottom: true, drawerMode: "RESPONSE_TIME" });
  }

  handleSearchClick = () => {
    // let currentSearchingState = this.state.isSearching;
    // this.setState({isSearching: !currentSearchingState});
    let currentView = this.state.conversationViewMode;
    this.setState({conversationViewMode: currentView === "SEARCH_CONTACTS" ? "ALL_CONTACTS" : "SEARCH_CONTACTS"});
  }

  handleCsHelpButtonClick = () => {
    // let currentCsHelpState = this.state.isCshelp;
    // this.setState({isCsHelp: !currentCsHelpState});
    let currentView = this.state.conversationViewMode;
    this.setState({conversationViewMode: currentView === "CS_HELP" ? "ALL_CONTACTS" : "CS_HELP"});
  }

  toggleDrawer = (anchor, open) => (event) => {
    console.log("TOGGLE");

    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }

    this.setState({ ...this.state, [anchor]: open });
  };

  handleLogout = () => {

    axios
      .post(Api.logout(), {userId: Global.get().user.id, appProfileId: Global.getAppProfile()._id}, Api.getRequestHeader())
      .then((response) => {
        console.log("Logout : ", response.data);
        if(response.data.success === true){
            clearTimeout(this.state.obTimeoutButtonCheckPoint);
            clearTimeout(this.state.obTimeoutCheckPoint);
            localStorage.removeItem("auth");
            this.props.onAuthChange();
        }else{
          var errorMessage = response.data.message + " yakni : ";
          response.data.data.forEach(element => {
            let contactInfo = element.fromName + "(" + element.from + "), ";

            errorMessage += contactInfo;
          });
          this.setState({errorMessage: errorMessage});
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });

    
  };

  handleNewMessageComing = (message) => {
    this.getConversations(false, "HANDLE_NEW_MESSAGE_COMING");
  };

  handleContactsCancelDashboardClick = () => {
    console.log("CLICKED");
    // this.toggleDrawer('bottom', true);
    this.setState({ ...this.state, bottom: true, drawerMode: "CANCELED_CONTACTS" });
  }

  handleOpenDashboardTrainingSPvClick = () => {
    console.log("CLICKED");
    // this.toggleDrawer('bottom', true);
    this.setState({ ...this.state, bottom: true, drawerMode: "DASHBOARD_TRAINING_SPV" });
  }

  getListSlowResponseMessage = () => {

    const params = {
      appProfileId: Global.getAppProfile()._id,
      userId: Global.get().user.id
    };

    console.log("SLOWRESPONSEPARAMS : ", params);

    axios
      .post(Api.getListSlowResponseMessage(), params, Api.getRequestHeader())
      .then((response) => {
        console.log("SLOWRESPONSEAPI : ", response.data);
        let data = response.data.data;

        let newSlow = data.map((result) => {
          result.contact = this.generateItemConversation(result);
          return result;
        })

        this.setState({listSlowResponse: newSlow});
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  getListJustReadMessage = () => {

    const params = {
      appProfileId: Global.getAppProfile()._id,
      userId: Global.get().user.id
    };

    console.log("JUSTREADPARAMS : ", params);

    axios
      .post(Api.getListJustReadMessage(), params, Api.getRequestHeader())
      .then((response) => {
        console.log("JUSTREADAPI : ", response.data);
        let data = response.data.data;

        let newSlow = data.map((result) => {
          // result.contact = {
          //   photo: "https://picsum.photos/seed/picsum/200",
          //   fromName: `${result.fromName}`,
          //   text: result.lastMessage,
          //   fromNumber: result.from,
          //   contactId: result.id,
          //   toNumber: result.to,
          //   toName: result.toName,
          //   selected: false,
          //   unreadCounter: result.unreadCounter,
          //   assignToUserName: result.assignToUserName,
          //   csHelpState: result.csHelpState,
          //   csId: result.csId,
          //   csName: result.csName,
          //   csArchived: result.csArchived,
          //   contactLabel: result.label,
          //   keterangan: result.keterangan,
          //   emailRegister: result.emailRegister
          // };
          result.contact = this.generateItemConversation(result);
          return result;
        })

        this.setState({listJustRead: newSlow});
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  getListUnfinishedChat = () => {

    const params = {
      appProfileId: Global.getAppProfile()._id,
      userId: Global.get().user.id
    };

    console.log("PARAMUNFINISHED", params);

    axios
      .post(Api.getListUnfinishedChat(), params, Api.getRequestHeader())
      .then((response) => {
        console.log("LISTUNFINISHEDCHAT : ", response.data);
        let data = response.data.data;

        let newUnfinished = data.map((result) => {
          result.contact = {
            photo: "https://picsum.photos/seed/picsum/200",
            fromName: result.fromName,
            text: result.lastMessage,
            fromNumber: result.from,
            contactId: result.id,
            toNumber: result.to,
            toName: result.toName,
            selected: false,
            unreadCounter: result.unreadCounter,
            assignToUserName: result.assignToUserName,
            csHelpState: result.csHelpState,
            csId: result.csId,
            csName: result.csName,
            csArchived: result.csArchived,
            contactLabel: result.label,
            deliveryDate: result.deliveryDate,
            productCategory: result.productCategory,
            cityName: result.cityName,
            keterangan: result.keterangan,
            emailRegister: result.emailRegister
          };
          return result;
        })

        this.setState({listUnfinishedChat: newUnfinished});
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  getListChatNggantung = () => {

    const params = {
      appProfileId: Global.getAppProfile()._id,
      userId: Global.get().user.id
    };

    console.log("PARAMCHATNGGANTUNG", params);

    axios
      .post(Api.listChatNggantung(), params, Api.getRequestHeader())
      .then((response) => {
        console.log("LISTCHATNGGANTUNG : ", response.data);
        let data = response.data.data;

        let chatNggantung = data.map((result) => {
          result.contact = {
            photo: "https://picsum.photos/seed/picsum/200",
            fromName: result.fromName,
            text: result.lastMessage,
            fromNumber: result.from,
            contactId: result.id,
            toNumber: result.to,
            toName: result.toName,
            selected: false,
            unreadCounter: result.unreadCounter,
            assignToUserName: result.assignToUserName,
            csHelpState: result.csHelpState,
            csId: result.csId,
            csName: result.csName,
            csArchived: result.csArchived,
            contactLabel: result.label,
            deliveryDate: result.deliveryDate,
            productCategory: result.productCategory,
            cityName: result.cityName,
            lastMessageReceivedAt: result.lastMessageReceivedAt,
            keterangan: result.keterangan,
            emailRegister: result.emailRegister
          };
          return result;
        })

        this.setState({listChatNggantung: chatNggantung});
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  getListCustomerWajibBayar = () => {

    const params = {
      appProfileId: Global.getAppProfile()._id,
      userId: Global.get().user.id
    };

    console.log("PARAMWAJIBBAYAR", params);

    axios
      .post(Api.listCustomerWajibBayar(), params, Api.getRequestHeader())
      .then((response) => {
        console.log("LISTWAJIBBAYAR : ", response.data);
        let data = response.data.data;

        let newUnfinished = data.map((result) => {
          result.contact = {
            photo: "https://picsum.photos/seed/picsum/200",
            fromName: result.fromName,
            text: result.lastMessage,
            fromNumber: result.from,
            contactId: result.id,
            toNumber: result.to,
            toName: result.toName,
            selected: false,
            unreadCounter: result.unreadCounter,
            assignToUserName: result.assignToUserName,
            csHelpState: result.csHelpState,
            csId: result.csId,
            csName: result.csName,
            csArchived: result.csArchived,
            contactLabel: result.label,
            deliveryDate: result.deliveryDate,
            productCategory: result.productCategory,
            cityName: result.cityName,
            keterangan: result.keterangan,
            emailRegister: result.emailRegister
          };
          return result;
        })

        this.setState({listCustomerWajibBayar: newUnfinished});
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  getMessageListComponent = () => {
    return (<MessageList
      onUpdateReadCounter = {this.handleUpdateUnreadCounter}
      onNewIncomingMessage={this.handleUpdateConversation}
      onUpdateConversations={this.handleReloadConversations}
      onUpdateCsHelpState={this.handleUpdateCsHelpState}
      currentConversation={this.state.currentConversation}
      onUpdateContactLabelState={this.handleUpdateContactLabelState}
      onClearCurrentConversation = {this.clearCurrentConversation}
      onUpdateSlow={this.getListSlowResponseMessage}
      onUpdateJustRead={this.getListJustReadMessage}
      onUpdateUnfinishedChat={this.getListUnfinishedChat}
      onResetUnreadCounter={this.resetUnreadCounter}
      conversationList={this.state.conversations}
      logoutHandler={this.handleLogout}
      onSaveCustomerName={this.handleSavenamaCustomer}
      generateItemConversation={this.generateItemConversation}
      handleConversationSelected={this.handleConversationSelected}
      onMarkAsRead={this.markAsRead}
      isMobile={this.isMobile}
    />);
  }

  handleShowSnackbar = (message) => {
    this.setState({snackbarVisible: true, snackbarMessage: message});
  }

  handleCloseSnackbar = () => {
      this.setState({snackbarVisible: false, snackbarMessage: ""});
  }

  handleToggleShowDailyFu = () => {
    let show = !this.state.showDailyFu;
    this.setState({showDailyFu: show});
  }

  handleInsertFuContactToConversationsList = (contact) => {

    const listConverstions = [...this.state.conversations];
    
    let newData = this.generateItemConversation(contact); 

    let existing = listConverstions.filter(item => item.fromNumber === newData.fromNumber);

    if(existing !== null && existing !== undefined && existing.length > 0){
      newData = existing[0];
    }else{
      listConverstions.splice(0, 0, newData);
    }

    
    this.setState({conversations: listConverstions, showDailyFu: false}, ()=>{
      localStorage.removeItem("ref");
      this.handleConversationSelected(newData);
    });

  }

  getListSalesOnline = () => {
    axios
      .post(Api.getSalesChatList(), {appProfileId: Global.getAppProfile()._id}, Api.getRequestHeader())
      .then((response) => {
          console.log("SALESCHATLIST : ", response.data.data);

          const responseData = response.data.data;

          let findSales = responseData.findIndex(item => item.lavenderSalesId === Global.get().user.id);

          this.setState({isUserAutoleadsOn: findSales >= 0});
      })
      .catch((error) => {
          console.error("There was an error!", error);
      });
  };

  handleCloseChat = () => {
    this.setState({currentConversation: {
      fromNumber: "",
      fromName: "",
      index: 0,
      contactId: 0,
      toNumber: 0,
      toName: "",
      unreadCounter: 0,
      assignToUser: 0,
      assignToUserName: ""
    }});
  }

  isMobile = () => {
    // return this.state.screenWidth < 768;
    return this.state.screenWidth < 780;
    // return false
  }

  render() {
    // console.log("MESSAGES", this.state.messages);
    return (
      <div className={this.isMobile() ? "messenger-mobile" : "messenger"}>
        {/* <Toolbar
          title="Messenger"
          leftItems={[<FaCog key="cog" icon="ion-ios-cog" />]}
          rightItems={[
            <ToolbarButton key="add" icon="ion-ios-add-circle-outline" />,
          ]}
        />

        <Toolbar
          title="Conversation Title"
          rightItems={[
            <ToolbarButton
              key="info"
              icon="ion-ios-information-circle-outline"
            />,
            <ToolbarButton key="video" icon="ion-ios-videocam" />,
            <ToolbarButton key="phone" icon="ion-ios-call" />,
          ]}
        /> */}

        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={this.state.snackbarVisible}
            autoHideDuration={30000}
            onClose={this.handleCloseSnackbar}
            message={this.state.snackbarMessage}
        />

        {
          // Global.get().admin || Global.isSales() || Global.isSalesDataPending() ? <NotifyFuSalesOnline mode="fab" handleConversationSelected={this.handleConversationSelected} generateItemConversation={this.generateItemConversation} /> : <></>
        }
        

        {
          this.state.generalDialogSetting.isOpen && <GeneralDialog settings={this.state.generalDialogSetting} />
        }

        {
          Global.isTeleCutomer() ? (
            <Fab variant="extended" sx={{position: "fixed", bottom: 16, left: 16}} color="primary" onClick={this.handleToggleShowDailyFu}>
              Follow Up&nbsp;&nbsp;&nbsp;
              <Campaign sx={{ mr: 1 }} />
            </Fab>
          ) : (<></>)
        }

        {
          !this.state.appIsUpdated ? (
            <Fab variant="extended" size="small" sx={{position: "fixed", top: 16, left: "45vw"}} color="secondary" onClick={() => window.location.reload(true)}>
              Klik untuk mengupdate Lotus ke versi {this.state.newVersion}&nbsp;&nbsp;&nbsp;&nbsp;
              <CloudSync />
            </Fab>
          ) : (<></>)
        }

        {
          // this.state.appIsUpdated ? (
            // <Fab variant="extended" size="small" sx={{position: "fixed", bottom: 16, right: "5vw"}} color="secondary" onClick={() => window.location.reload(true)}>
            //   <Campaign />
            // </Fab>
          // ) : (<></>)
        }

        {/* <Slide direction="up" in={this.state.showDailyFu} mountOnEnter unmountOnExit>
          <DailyFollowUp openChat={this.openTeleChat} onClose={this.handleToggleShowDailyFu} />
        </Slide> */}

        {
          this.state.showDailyFu ? (
            // <Slide direction="up" in={this.state.showDailyFu} mountOnEnter unmountOnExit>
              <DailyFollowUp addToConversationList={this.handleInsertFuContactToConversationsList} openChat={this.openTeleChat} onClose={this.handleToggleShowDailyFu} conversations={this.state.conversations}/>
            // </Slide>
            ) : (<></>)
        }

        {['left', 'right', 'top', 'bottom'].map((anchor) => (
            <React.Fragment key={anchor}>
                {/* <Button onClick={this.toggleDrawer(true)}>{anchor}</Button> */}
                <Drawer anchor={anchor} open={this.state[anchor]} 
                onClose={this.toggleDrawer('bottom',false)}
                >
                  <div style={{minHeight: "90vh"}}>
                    {
                      this.state.drawerMode === "HANDOVER" ? 
                      (
                        <Handover 
                          onUpdateConversations={this.handleReloadConversations} 
                          onClose={this.toggleDrawer('bottom',false)}/>
                      ) : (<></>)
                    }

                    {
                      this.state.drawerMode === "CONTACTS" && Global.isPurchasingApp() ? 
                        (
                          <ContactsSupplierDashboard onCheckIfConversationExists={this.checkIfConversationExists} onRefresh={() => this.getConversations(true, "CONTACT_SUPPLIER_DASHBOARD")} onSelect={this.handleConversationSelected} />
                        ) : 
                        this.state.drawerMode === "CONTACTS" ? 
                        (
                          <ContactsDashboard onSelect={this.handleConversationSelected} onClose={this.toggleDrawer('bottom',false)}/>
                        ) : (<></>)
                    }

                    {
                      this.state.drawerMode === "REPORT" ? 
                      (
                        <Paper>
                          <div className="container-fluid">
                            <br />
                            <div className="row">
                                <div className="col-11"><h1>Leads Report</h1></div>
                                <div className="col-1">
                                    <div className="btn btn-danger" onClick={this.toggleDrawer('bottom',false)}>
                                        X
                                    </div>
                                </div>
                            </div>
                            <br />
                            <ContactLeadsReport onClose={this.toggleDrawer('bottom',false)}/>
                          </div>
                        </Paper>
                      ) : (<></>)
                    }

                    {
                      this.state.drawerMode === "RESPONSE_TIME" ? 
                      (
                        <Paper>
                          <div className="container-fluid" style={{margin: "20px"}}>
                            <div className="row">
                                <div className="col-11"><h1>Response Time Report</h1></div>
                                <div className="col-1">
                                    <div className="btn btn-danger" onClick={this.toggleDrawer('bottom',false)}>
                                        X
                                    </div>
                                </div>
                            </div>
                            <br />
                            <SalesResponseTimeReport onClose={this.toggleDrawer('bottom',false)}/>
                          </div>
                        </Paper>
                      ) : (<></>)
                    }

                    {
                      this.state.drawerMode === "DATA_CANCEL" ? 
                      (
                        <Paper style={{padding: "10px", margin: "10px", minHeight: "80vh"}}>
                          <div className="container-fluid">
                            <div className="row">
                                <div className="col-11"><h1>Data Pending</h1></div>
                                <div className="col-1">
                                    <div className="btn btn-danger" onClick={this.toggleDrawer('bottom',false)}>
                                        X
                                    </div>
                                </div>
                            </div>
                            <br />
                            <DataPending onClose={this.toggleDrawer('bottom',false)}/>
                          </div>
                        </Paper>
                      ) : (<></>)
                    }

                    {
                      this.state.drawerMode === "CANCELED_CONTACTS" ? 
                      (
                        <ContactsCancelDashboard onSelect={this.handleConversationSelected} onClose={this.toggleDrawer('bottom',false)}/>
                      ) : (<></>)
                    }
                  </div>
                </Drawer>
            </React.Fragment> 
        ))}
        
        {
          this.isMobile() && this.state.currentConversation.fromNumber !== "" ? <></> : 
          <div className="scrollable sidebar">
            <ConversationList
                key="conversation-list"
                onNewClick={this.handleLogoutClick}
                onSettingClick={this.handleSettingClick}
                onSelect={this.handleConversationSelected}
                onHandoverClick={this.handleHandoverClick}
                onContactsDashboardClick={this.handleContactsDashboardClick}
                onContactsCancelDashboardClick={this.handleContactsCancelDashboardClick}
                onOpenDashboardTrainingSpvClick={this.handleOpenDashboardTrainingSPvClick}
                onLeadsReportClick={this.handleLeadsReportClick}
                onDataCancelClick={this.handleDataCancelClick}
                onResponseTimeClick={this.handleResponseTimeClick}
                conversationData={this.state.conversations}
                loadNext={this.getConversations}
                onSearchClick={this.handleSearchClick}
                searchStatus={this.state.isSearching}
                conversationViewMode={this.state.conversationViewMode}
                csHelpCount={this.state.csHelpCounter}
                onCsHelpButtonClick={this.handleCsHelpButtonClick}
                filterLabel={this.state.filterLabel}
                onFilterLabelChange={this.handleChangeFilterLabel}
                slowResponseMessages={this.state.listSlowResponse}
                justReadMessages={this.state.listJustRead}
                listUnfinishedChat={this.state.listUnfinishedChat}
                onUpdateSlow={this.getListSlowResponseMessage}
                onUpdateJustRead={this.getListJustReadMessage}
                onUpdateUnfinishedChat={this.getListUnfinishedChat}
                listCustomerWajibBayar={this.state.listCustomerWajibBayar}
                onUpdateWajibBayar={this.getListCustomerWajibBayar}
                listChatNggantung={this.state.listChatNggantung}
                onUpdateChatNggantung={this.getListChatNggantung}
                showButtonCheckPoint={this.state.showButtonCheckPoint}
                assignCheckPoint={this.assignCheckPoint}
                filterHasilFu={this.state.filterHasilFu}
                onFilterHasilFuChange={this.handleChangeFilterHasilFu}
                onUnreadConversationOnlyChange={this.handleUnreadConversationOnlyChange}
                getMessageListComponent={this.getMessageListComponent}
                toggleCrossedLeads={this.state.toggleCrossedLeads}
                onToggleCrossedLeadsChange={this.onToggleCrossedLeadsChange}
                isUserAutoleadsOn={this.state.isUserAutoleadsOn}
                showSnackbar={this.handleShowSnackbar}
                currentConversation={this.state.currentConversation}
                onCheckCustomerFu={this.checkCustomerFu}
                generateItemConversation={this.generateItemConversation}
                isMobile={this.isMobile}
              >

                

              {/* <InfiniteScroll
                    dataLength={this.state.conversations} //This is important field to render the next data
                    next={this.getConversations}
                    hasMore={true}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                      <p style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                    // below props only if you need pull down functionality
                    refreshFunction={() => {console.log("REFRESHING");}}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={
                      <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                    }
                    releaseToRefreshContent={
                      <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                    }
                  >
                    
                </InfiniteScroll> */}
              </ConversationList> 
              
            {/* )
          } */}
          
        </div>
        }

        {
          // this.isMobile() && this.state.currentConversation.fromNumber === "" ? <></> :
          <div className= {this.isMobile() && this.state.currentConversation.fromNumber === "" ? "display-none" : "scrollable content"}>
            <MessageList
              onUpdateReadCounter = {this.handleUpdateUnreadCounter}
              onNewIncomingMessage={this.handleUpdateConversation}
              onUpdateConversations={this.handleReloadConversations}
              onUpdateCsHelpState={this.handleUpdateCsHelpState}
              currentConversation={this.state.currentConversation}
              onUpdateContactLabelState={this.handleUpdateContactLabelState}
              onClearCurrentConversation = {this.clearCurrentConversation}
              onUpdateSlow={this.getListSlowResponseMessage}
              onUpdateJustRead={this.getListJustReadMessage}
              onUpdateUnfinishedChat={this.getListUnfinishedChat}
              onResetUnreadCounter={this.resetUnreadCounter}
              conversationList={this.state.conversations}
              logoutHandler={this.handleLogout}
              onSaveCustomerName={this.handleSavenamaCustomer}
              generateItemConversation={this.generateItemConversation}
              handleConversationSelected={this.handleConversationSelected}
              onUpdateUserAutoleadsState={this.handleUpdateUserAutoleadsState}
              onMarkAsRead={this.markAsRead}
              onClose={this.handleCloseChat}
              showButtonClose={true}
              isMobile={this.isMobile}
            />
          </div>
        }
        
        <Modal
          show={this.state.showSettingDialog}
          size="lg"
          onHide={this.handleCloseSettingDialog}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>Phone Number Assignment</Modal.Header>
          <Modal.Body>Pemberian hak nomor telepon customer kepada sales</Modal.Body>
          <Modal.Footer>
            <span
              onClick={this.handleCloseSettingDialog}
              className="btn btn-danger"
            >
              Close
            </span>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showLogoutDialog}
          onHide={this.handleCloseLogoutDialog}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>Konfirmasi</Modal.Header>
          <Modal.Body>
            Yakin akan keluar dari aplikasi?
            <br />

            <div className="text-danger">
              {this.state.errorMessage}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <span onClick={this.handleLogout} className="btn btn-danger">
              Keluar Aplikasi
            </span>
            <span
              onClick={this.handleCloseLogoutDialog}
              className="btn btn-warning"
            >
              Batalkan
            </span>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Messenger;
