import moment from "moment";

class Global {
	_data = [];
	_properties = {isConsoleEnabled: false};
	waMessageTemplate = {
		template: {}
	};

	socketData = {
		socket: {}
	};

	add(item) {
		this._data.pop();
		this._data.push(item);
	}

	getStringRequirements(){
		return "lotus#Requirements$Is!Ok";
	}

	get() {
		return this._data.length > 0 ? this._data[0] : null;
	}

	getPropertiesConsoleEnabled(){
		return this._properties.isConsoleEnabled;
	}

	setPropertiesConsoleEnabled(value){
		return this._properties.isConsoleEnabled = value;
	}

	getAppProfile() {
		return this._data.length > 0 ? this._data[0].appProfile : null;
	}

	getCheckPoint() {
		return this._data.length > 0 ? this._data[0].checkPoint : null;
	}

	isPurchasingApp() {
		return this._data.length > 0 && this._data[0].appProfile.type === "PURCHASING-APP" ? true : false;
	}

	setMessageTemplate(waMessageTemplate){
		this.waMessageTemplate.template = waMessageTemplate;
		//   console.log("SETTEMPLATE", this.waMessageTemplate.template);
	}

	getMessageTemplate(){
		// console.log("GETTEMPLATE", this.waMessageTemplate.template);
		let newTemplate = JSON.parse(JSON.stringify(this.waMessageTemplate.template));
		return newTemplate;
	}

	isCustomerService(){
		let userData = this._data.length > 0 ? this._data[0] : null;
		return userData !== null && (["customer-service", "cs-purchase", "purchasing"].includes(userData.user.role));
	}

	isCustomerServiceSpv(){
		let userData = this._data.length > 0 ? this._data[0] : null;
		return userData !== null && userData.user.role === "customer-service-supervisor";
	}

	isSales(){
		let userData = this._data.length > 0 ? this._data[0] : null;
		return userData !== null && userData.user.role === "sales";
	}

	isSalesDataPending(){
		let userData = this._data.length > 0 ? this._data[0] : null;
		return userData !== null && userData.user.role === "sales-data-pending";
	}

	isYana(){
		let userData = this._data.length > 0 ? this._data[0] : null;
		return userData !== null && userData.user.id === 142;
	}

	isTeleAdmin(){
		let userData = this._data.length > 0 ? this._data[0] : null;
		if(userData){
			if(userData.user.role === "operational-tele-supervisor" | userData.user.role === "sales-acquisition"){
				return true;
			}else return false;
		}else{
			return false;
		}
		// return userData !== null && (userData.user.role === "operational-tele-supervisor" | userData.user.role === "sales-acquisition");
	}

	isAdminTeleUser(){
		let userData = this._data.length > 0 ? this._data[0] : null;
		let result = userData !== null && (userData.user.role === "admin-tele" | userData.user.role === "sales-acquisition");
		if(result === 0){
		return false;
		}else if(result === 1){
		return true;
		}
		return result;
	}

	isAdminEpUser(){
		let userData = this._data.length > 0 ? this._data[0] : null;
		return userData !== null && (userData.user.role === "admin-ep" | userData.user.role === "sales-acquisition");
	}

	isAdminDataCancel(){
		let userData = this._data.length > 0 ? this._data[0] : null;
		return userData !== null && (userData.user.role === "admin-data-cancel");
	}

	isTeleUser(){
		let userData = this._data.length > 0 ? this._data[0] : null;
		return userData !== null && (userData.user.role === "operational-tele-supervisor" | userData.user.role === "account-executive" | userData.user.role === "nightshift" | userData.user.role === "sales-acquisition" | userData.user.role === "tele-leader");
	}

	isTeleCutomer(){
		let userData = this._data.length > 0 ? this._data[0] : null;
		return userData !== null && (userData.user.role === "account-executive" | userData.user.role === "nightshift" | userData.user.role === "sales-acquisition" | userData.user.role === "tele-leader");
	}

	isSuperUser(){
		let userData = this._data.length > 0 ? this._data[0] : null;
		return userData !== null && (userData.user.role === "it-database" | userData.user.role === "product-manager");
	}

	isOperationalManager(){
		let userData = this._data.length > 0 ? this._data[0] : null;
		return userData !== null && (userData.user.role === "operational-manager");
	}

	isCsPurchaseSpv(){
		let userData = this._data.length > 0 ? this._data[0] : null;
		return userData !== null && (userData.user.role === "cs-purchase-spv");
	}

	labelSemuaLabel(){
		return "Semua label";
	}

	labelPaid(){
		return "PAID";
	}

	labelDp(){
		return "DP";
	}

	labelUnpaid(){
		return "UNPAID";
	}

	labelPending(){
		return "PENDING";
	}

	labelCancel(){
		return "CANCEL";
	}

	labelRandomLeads(){
		return "RANDOM LEADS";
	}

	labelComplaint(){
		return "COMPLAINT";
	}

	labelKonfirmasi(){
		return "KONFIRMASI";
	}

	labelClosing(){
		return "CLOSING";
	}
	
	labelProspekEp(){
		return "PROSPEK EP";
	}
	
	labelUplineEP(){
		return "UPLINE EP";
	}

	labelRegisterApps(){
		return "REGISTER APPS"
	}

	labelComplaintKeterlambatan(){
		return "Complaint Keterlambatan";
	}

	labelComplaintProdukTidakSesuai(){
		return "Complaint Produk Tidak Sesuai";
	}

	labelComplaintLayuRusak(){
		return "Complaint Layu / Rusak";
	}

	labelComplaintReport(){
		return "Complaint Report"
	}

	labelReport(){
		return "Report"
	}

	labelKonfirmasiPesanan(){
		return "Konfirmasi Pesanan";
	}

	labelComplaintPesananTidakDibuatkan(){
		return "Complaint Pesanan Tidak Dibuatkan";
	}

	labelLainLain(){
		return "Lain-lain";
	}

	tipeLeadSulitClosing = () => {
		return "Sulit Closing";
	}
	tipeLeadSedang = () => {
		return "Sedang";
	}
	tipeLeadMudahClosing = () => {
		return "Mudah Closing";
	}

	getLabelClasses(label) {
		let classes = "badge badge-pill ";

		if(label === this.labelPaid()){
			classes += "badge-success";
		}else if(label === this.labelDp()){
			classes += "badge-secondary";
		}else if(label === this.labelUnpaid()){
			classes += "badge-primary";
		}else if(label === this.labelPending()){
			classes += "badge-info";
		}else if(label === this.labelCancel()){
			classes += "badge-danger";
		}else if(label === this.labelClosing()){
			classes += "badge-danger";
		}else if(label === this.labelComplaint()){
			classes += "badge-danger";
		}else if(label === this.labelKonfirmasi()){
			classes += "badge-danger";
		}else if(label === this.labelRandomLeads()){
		classes += "badge-dark";
		}else if(label === this.labelRegisterApps()){
		// classes += "badge-dark";
			classes += "label-color-register-apps";
		}else if(label === this.labelComplaintKeterlambatan()){
			classes += "label-color-complaint-keterlambatan";
		}else if(label === this.labelComplaintProdukTidakSesuai()){
			classes += "label-color-complaint-produk-tidak-sesuai";
		}else if(label === this.labelComplaintLayuRusak()){
			classes += "label-color-complaint-layu-rusak";
		}else if(label === this.labelComplaintReport()){
			classes += "label-color-complaint-report";
		}else if(label === this.labelReport()){
			classes += "label-color-report";
		}else if(label === this.labelKonfirmasiPesanan()){
			classes += "label-color-konfirmasi-pesanan";
		}else if(label === this.labelLainLain()){
			classes += "label-color-lain-lain";
		}else if(label === this.labelComplaintPesananTidakDibuatkan()){
			classes += "label-color-complain-pesanan-tidak-dibuatkan";
		}else if(label === "Semua label"){
			classes += "badge-dark";
		}

		// classes += label === true ? "selected" : "";
		return classes;
	}

	getListLabels = () => {

		return [
		this.labelPaid(),
		this.labelDp(),
		this.labelUnpaid(),
		this.labelCancel(),
		this.labelRandomLeads(),
		this.labelPending(),
		this.labelRegisterApps(),
		this.labelComplaintKeterlambatan(),
		this.labelComplaintProdukTidakSesuai(),
		this.labelComplaintLayuRusak(),
		this.labelComplaintReport(),
		this.labelReport(),
		this.labelKonfirmasiPesanan(),
		this.labelLainLain(),
		this.labelComplaintPesananTidakDibuatkan()
		];

	}

	getListProductCategories(){
		return [
		"Bunga Papan Duka Cita",	
		"Bunga Papan Congratulation",	
		"Bunga Papan Wedding",
		"Handbouquet",	
		"Handbouquet and Chocholate",	
		"Cake",
		"Flower Box",	
		"Bunga Meja",	
		"Bunga Bang",	
		"Standing Flower Duka Cita",	
		"Standing Flower Congratulation",	
		"Standing Flower Wedding",	
		"Parcel Buah",	
		"Hampers Baby",	
		"Parcel Makanan",	
		"Parcel Pecah Belah",	
		"Parcel Sembako",	
		"Parcel Cookies",	
		"Handbouquet dan Boneka",	
		"Kalung Bunga",	
		"Bunga Mobil",	
		"Bunga Gunting Pita",	
		"Bunga Tabur",
		"Lain-lain",
		"Belum diketahui",
		"Leads Natal",
		"Leads Lebaran",
		"Leads Valentine",
		"Leads Imlek"
		];
	}

	getListKeteranganPending(){
		return [
		"Masih tunggu konfirmasi",
		"Masih pilih produk",
		"Masih nego estimasi jam kirim",
		"Pikir-pikir dulu",
		"Belum respon",
		"Baru tagline",
		"Design tidak sesuai",
		"Budget tidak cukup",
		"Pengiriman express",
		"Produk tidak tersedia",
		"Minta Free Ongkir",
		"Request Design",
		"Masih pilih produk",
		"Sudah pesan di tempat lain",
		"Sudah dipesankan oleh rekan",
		"Atasan meminta pending",
		"Kordinasi sama atasan/rekan",
		"Minta DP",
		"Minta Discount",
		"Menanyakan Lokasi/Alamat Prestisa",
		"Ingin COD",
		"Untuk pemesanan lain waktu",
		"Survey/minta pricelist",
		"Tidak ada respon",
		"Telat Response",
		"Respon belum sesuai", 
		"Leads Tagline",
		"Mitra Tidak Tersedia",
		"Lokasi Pengiriman Tidak terjangkau",
		"Masih tunggu konfirmasi"
		];
	}

	getListKeteranganCancel(){
		return [
		"Budget tidak cukup",
		"Sudah dipesankan rekan",
		"Estimasi under 3jam",
		"Tidak ada respon",
		"Produk tidak tersedia",
		"Mitra tidak tersedia",
		"Hanya tagline",
		"Penerima tidak berkenan menerima Produk",
		"Budget tidak cukup karena ada ongkir", 
		"Closing di data cancel",
		"COD"
		];
	}

	getListHasilFu = () => {
		return [
		// "NO RESPON",
		// "RESPON TERTARIK",
		// "REGISTER",
		// "MENOLAK",
		// "HANYA DI READ",
		// "NO INVALID",
		// "COMPLAINT",
		// "RESPON CHAT AWAL SAJA",
		// "RESPON BIASA SAJA",
		"Respon",
		"No Respon",
		"Closing"
		// "Respon Tertarik",
		// "Register",
		// "Order",
		// "Menolak",
		// "No Invalid",
		// "Download Apps"
		];
	}

	// getListKategoriProblem = () => {
	//   return [
	//     "Respon Sales Kurang Cepat",
	//     "Sales Tidak Memberikan Alternatif Produk",
	//     "Sales Tidak Memberikan Promo",
	//     "Product Knowledge Sales Kurang",
	//     "Sales Pasif",
	//     "Estimasi Pengiriman < 3 Jam",
	//     "Tidak mau dikenakan biaya VIP",
	//     "Produk Tidak Tersedia",
	//     "COD",
	//     "Leads Tidak Sesuai Target",
	//     "Lain-Lain"
	//   ];
	// }
	getListKategoriProblem = () => {
		return [
		"Respon sales",
		"Tidak ada supplier",
		"Produk belum tersedia",
		"Budget customer tidak cukup",
		"Tagline",
		"Design tidak sesuai daerah",
		"Estimasi waktu",
		"Jeda chat terakhir ke FU terlalu lama",
		"Cancel dari customer",
		"Sudah dipesankan rekan",
		"Budget tidak cukup karena ada ongkir",
		"COD",
		"Respon sales lambat",
		"Closing di Data Cancel"
		];
	}

	getPriceRange = () => {
		return [
			"100.000 - 300.000",
			"300.000 - 500.000",
			"500.000 - 800.000",
			"800.000 - 1.000.000",
			"1.000.000 - 1.500.000",
			"1.500.000 - 300.000",
		];
	}

	getKategoriKonfirmasi = () => {
		return [
			"Faktur Pajak",
			"Invoice Paid",
			"Foto Hasil/Lokasi",
			"Pembayaran"
		];
	}

	setSocket(socket){
		this.socketData.socket = socket;
	}

	getSocket(){
		return this.socketData.socket;
	}

	isNeedUnfinishedChatReminder(role){

		let arrRoles = [
		"operational-tele-supervisor",
		"operational-tele-supervisor",
		"nightshift",
		"account-executive",
		"tele-leader"
		];

		return !arrRoles.includes(role);

	}

	treePermasalan = [
			{
				permasalahan: "Product",
				alasanUmum: [
				{
					name: "Design",
					alasanRinci: [
					"Design kurang menarik",
					"Design yang diinginkan tidak sesuai daerah pengiriman",
					"Design produk dengan harga tidak sesuai",
					"Pilihan design di web sedikit"
					]
				},
				{
					name: "Estimasi Pengiriman",
					alasanRinci: [
					"Estimasi under 3 jam",
					"Customer telat pesan",
					"Produk yang diinginkan tidak bisa express"
					]
				},
				{
					name: "Produk tidak tersedia",
					alasanRinci: [
					"Produk yang ingin dipesan harus PO",
					"Produk tidak tersedia dikota tujuan pengiriman"
					]
				},
				]
			},
			{
				permasalahan: "Customer",
				alasanUmum: [
				{
					name: "Sudah pesan di tempat lain / oleh rekan",
					alasanRinci: [
						"Harga yang ditawarkan sales start 700rb",
						"Jeda FU sales >30 menit",
						"Tidak ada FU sales lebih lanjut",
						"Dapat harga yang lebih murah",
						"Double pesan dengan rekan",
						"Sales slow respon",
						"Design kurang menarik",
						"Contoh yang ditawarkan < 5 contoh",
					]
				},
				{
					name: "Kordinasi sama atasan/rekan",
					alasanRinci: [
						"Masih mempertimbangkan harga",
						"Masih pilih design",
						"Masih menunggu approval atasan",
						"Double customer yang menghubungi Prestisa"
					]
				},
				{
					name: "Menanyakan Lokasi",
					alasanRinci: [
						"Ingin pembelian secara offline",
						"Ingin pengiriman cepat cari yang dekat lokasi pengiriman",
						"Ingin COD",
						"Estimasi ingin lebih cepat (pick up)",
						"Ingin melihat langsung contoh asli produk",
					]
				},
				{
					name: "Untuk pemesanan lain waktu",
					alasanRinci: [
						"Acara masih dilain waktu",
						"Masih survei harga"
					]
				},
				{
					name: "Pembayaran",
					alasanRinci: [
						"Pembayaran ingin tempo",
						"Pembayaran ingin dp 50%",
						"Sales tidak menawarkan DP",
						"Pembayaran ingin DP <50%",
						"Customer ingin pembayaran setelah pesanan dikirim",
					]
				},
				{
					name: "Tidak jadi beli",
					alasanRinci: [
						"Penerima tidak berkenan menerima produk",
						"Acara customer batal",
						"Cancel dari customer tanpa alasan",
					]
				},
				{
					name: "Masih pilih produk",
					alasanRinci: [
						"Produk kurang bervariasi",
						"Masih ingin tahu detail produk",
						"Harga belum ada sesuai budget",
						"Produk tidak sesuai dengan design customer",
					]
				}
				
				]
			},
			{
				permasalahan: "Mitra",
				alasanUmum: [
					{
						name: "Mitra tidak tersedia",
						alasanRinci: [
							"Produk tidak tersedia",
							"Alamat pengiriman beda pulau/di pelosok",
						]
					}
				]
			},
			{
				permasalahan: "Sales",
				alasanUmum: [
					{
						name: "Telat response",
						alasanRinci: [
							"Ada masalah koneksi",
							"Ada problem system",
							"Jumlah leads yang masuk lebih banyak",
							"Sales sedang jam istirahat",
							"Sales tidak merespon",
						]
					},
					{
						name: "Respon belum sesuai ",
						alasanRinci: [
							"Sales tidak menjalankan SOP",
							"Alternatif jawaban case belum ada"
						]
					},
				]
			},
			{
				permasalahan: "Other",
				alasanUmum: [
					{
						name: "Leads Tagline",
						alasanRinci: [
							"Respon sales lambat",
							"Leads tidak tertarget",
							"Respon sales belum sesuai"
						]
					},
					{
						name: "Leads Random",
						alasanRinci: [
							"Leads tidak tertarget",
						]
					},
				]
			},
			{
				permasalahan: "Harga",
				alasanUmum: [
					{
						name: "Budget tidak cukup",
						alasanRinci: [
							"Tidak ada mitra terdekat",
							"Ada biaya ongkir",
							"Budget customer terlalu rendah",
							"Dapat lebih murah"
						]
					}
				]
			}
		];

	// treePermasalan = [
	// 		{
	// 			permasalahan: "Product",
	// 			alasanUmum: [
	// 			{
	// 				name: "Design tidak sesuai",
	// 				alasanRinci: [
	// 				"Design kurang menarik",
	// 				"Design yang diinginkan tidak sesuai daerah pengiriman",
	// 				"Design produk dengan harga tidak sesuai"
	// 				]
	// 			},
	// 			{
	// 				name: "Harga",
	// 				alasanRinci: [
	// 				"Budget customer tidak cukup range harga <250rb",
	// 				"Budget customer tidak cukup range harga 250rb-400rb",
	// 				"Budget customer tidak cukup range harga 400rb-600rb",
	// 				"Budget customer tidak cukup range harga 600rb-800rb",
	// 				"Budget customer tidak cukup range harga 800rb-1juta",
	// 				"Harga produk yang diberikan kurang bervariasi",
	// 				"Customer minta free ongkir",
	// 				"Customer minta diskon"
	// 				]
	// 			},
	// 			{
	// 				name: "Estimasi Pengiriman",
	// 				alasanRinci: [
	// 				"Estimasi under 3 jam",
	// 				"Customer telat pesan",
	// 				"Produk yang diinginkan tidak bisa express"
	// 				]
	// 			},
	// 			{
	// 				name: "Produk tidak tersedia",
	// 				alasanRinci: [
	// 				"Produk yang ingin dipesan harus PO",
	// 				"Produk tidak tersedia dikota tujuan pengiriman"
	// 				]
	// 			},
	// 			]
	// 		},
	// 		{
	// 			permasalahan: "Customer",
	// 			alasanUmum: [
	// 			{
	// 				name: "Sudah pesan di tempat lain / oleh rekan",
	// 				alasanRinci: [
	// 					"Harga yang ditawarkan sales start 700rb",
	// 					"Jeda FU sales >30 menit",
	// 					"Tidak ada FU sales lebih lanjut",
	// 					"Dapat harga yang lebih murah",
	// 					"Double pesan dengan rekan",
	// 					"Sales slow respon",
	// 					"Design kurang menarik",
	// 					"Contoh yang ditawarkan < 5 contoh",
	// 				]
	// 			},
	// 			{
	// 				name: "Kordinasi sama atasan/rekan",
	// 				alasanRinci: [
	// 					"Masih mempertimbangkan harga",
	// 					"Masih pilih design",
	// 					"Masih menunggu approval atasan",
	// 					"Double customer yang menghubungi Prestisa"
	// 				]
	// 			},
	// 			{
	// 				name: "Menanyakan Lokasi",
	// 				alasanRinci: [
	// 					"Ingin pembelian secara offline",
	// 					"Ingin pengiriman cepat cari yang dekat lokasi pengiriman",
	// 					"Ingin COD",
	// 					"Estimasi ingin lebih cepat (pick up)",
	// 					"Ingin melihat langsung contoh asli produk",
	// 				]
	// 			},
	// 			{
	// 				name: "Untuk pemesanan lain waktu",
	// 				alasanRinci: [
	// 					"Acara masih dilain waktu",
	// 					"Masih survei harga"
	// 				]
	// 			},
	// 			{
	// 				name: "Tidak ada respon",
	// 				alasanRinci: [
	// 					"Harga yang ditawarkan sales start 700rb",
	// 					"Pengiriman contoh dan harga belum bervariasi",
	// 					"Customer hanya survey produk",
	// 					"Customer hanya survey lokasi",
	// 					"Tidak jadi beli",
	// 					"Jeda FU sales >30 menit",
	// 					"Respon sales pasif",
	// 				]
	// 			},
	// 			{
	// 				name: "Pembayaran",
	// 				alasanRinci: [
	// 					"Pembayaran ingin tempo",
	// 					"Pembayaran ingin dp 50%",
	// 					"Sales tidak menawarkan DP",
	// 					"Pembayaran ingin DP <50%",
	// 					"Customer ingin pembayaran setelah pesanan dikirim",
	// 				]
	// 			},
	// 			{
	// 				name: "Tidak jadi beli",
	// 				alasanRinci: [
	// 					"Penerima tidak berkenan menerima produk",
	// 					"Acara customer batal",
	// 					"Cancel dari customer tanpa alasan",
	// 				]
	// 			},
	// 			{
	// 				name: "Masih pilih produk",
	// 				alasanRinci: [
	// 					"Produk kurang bervariasi",
	// 					"Masih ingin tahu detail produk",
	// 					"Harga belum ada sesuai budget",
	// 					"Produk tidak sesuai dengan design customer",
	// 				]
	// 			}
				
	// 			]
	// 		},
	// 		{
	// 			permasalahan: "Supplier",
	// 			alasanUmum: [
	// 				{
	// 					name: "Mitra tidak tersedia",
	// 					alasanRinci: [
	// 						"Produk tidak tersedia",
	// 						"Alamat pengiriman beda pulau/di pelosok",
	// 					]
	// 				}
	// 			]
	// 		},
	// 		{
	// 			permasalahan: "Sales",
	// 			alasanUmum: [
	// 				{
	// 					name: "Telat response",
	// 					alasanRinci: [
	// 						"Ada masalah koneksi",
	// 						"Ada problem system",
	// 						"Jumlah leads yang masuk lebih banyak",
	// 						"Sales sedang jam istirahat",
	// 						"Sales tidak merespon",
	// 					]
	// 				},
	// 				{
	// 					name: "Respon belum sesuai ",
	// 					alasanRinci: [
	// 						"Sales tidak menjalankan SOP",
	// 						"Alternatif jawaban case belum ada"
	// 					]
	// 				},
	// 			]
	// 		},
	// 		{
	// 			permasalahan: "Other",
	// 			alasanUmum: [
	// 				{
	// 					name: "Leads Tagline",
	// 					alasanRinci: [
	// 						"Respon sales lambat",
	// 						"Leads tidak tertarget",
	// 						"Respon sales belum sesuai",
	// 						"Tagline"
	// 					]
	// 				},
	// 				{
	// 					name: "Leads Random",
	// 					alasanRinci: [
	// 						"Leads tidak tertarget",
	// 					]
	// 				},
	// 			]
	// 		},
	// 	];

	getListPermasalahan = () => {
		return this.treePermasalan;
	}

	getListAlasanUmum = (permasalahan) => {
		let itemPermasalahan = this.treePermasalan.filter(item => item.permasalahan === permasalahan);
		if(itemPermasalahan && itemPermasalahan.length > 0){
			return itemPermasalahan[0].alasanUmum;
		}
		return [];
	}

	getListAlasanRinci = (permasalahan, alasanUmum) => {
		let itemPermasalahan = this.treePermasalan.filter(item => item.permasalahan === permasalahan);
		if(itemPermasalahan && itemPermasalahan.length > 0){
			let itemAlasanUmum = itemPermasalahan[0].alasanUmum.filter(item => item.name === alasanUmum);
			if(itemAlasanUmum && itemAlasanUmum.length > 0){
				return itemAlasanUmum[0].alasanRinci;
			}
		}

		return [];
		
	}

	maskPhoneNumber = (phoneNumber, forceMask = false) => {
		// return phoneNumber.slice(0, -5) + Array(5 + 1).join("x");

		// if(!this.isTeleCutomer()){
		//   return phoneNumber;
		// }

		// if(this.isSales() || this.isSalesDataPending()){
		//   return phoneNumber;
		// }
		if(forceMask !== true){
			if(this.isSalesDataPending() || this.isOperationalManager() || this.isSuperUser() || this.isCsPurchaseSpv()){
				return phoneNumber;
			}
		}
		// return phoneNumber;
		try{
		// console.log(phoneNumber);
		if(phoneNumber !== null && phoneNumber !== undefined && phoneNumber.length > 9){
			let phoneNumberLength = phoneNumber.length;

			let prefix = phoneNumber;
			let suffix = phoneNumber;

			return prefix.slice(0,5) + Array(phoneNumberLength-9+1).join("*") + suffix.slice(-4);
		}else{
			return phoneNumber;
		}
		
		}catch(error){
		return phoneNumber;
		}
		
	}

	formatTime = (seconds) => {
		var duration = moment.duration(seconds, 'seconds');
		var formatted = duration.format("hh:mm:ss");
		return seconds < 60 ? "00:"+formatted : formatted;
	}

	convertTime12to24 = (time12h) => {
		const [time, modifier] = time12h.split(" ");

		let [hours, minutes, seconds] = time.split(":");

		if (hours === "12") {
		hours = "00";
		}

		if (modifier === "PM") {
		hours = parseInt(hours, 10) + 12;
		}

		return `${hours}:${minutes}:${seconds}`;
	};

	/** Format datetime to String Date And Time 24 Hours Format */
	formatDateTimeToStrDateTime24 = (datetime, isExcludeSeconds = false) => {

		let strFormat = !isExcludeSeconds ? "DD-MM-Y HH:mm:ss" : "DD-MM-Y HH:mm";

		let formatted = moment(datetime).tz("asia/jakarta").format(strFormat);
		return formatted;
	}

	/** Format Unix TimeStamps to String Date And Time 24 Hours Format */
	formatUnixTimeStampsToStrDateTime24 = (unixTimeStamps, isExcludeSeconds = false) => {

		let strFormat = !isExcludeSeconds ? "DD-MM-Y HH:mm:ss" : "DD-MM-Y HH:mm";

		let formatted = moment(unixTimeStamps).tz("asia/jakarta").format(strFormat);
		return formatted;
	}

	/** Format datetime to String Date And Time 24 Hours Format */
	formatDateTimeToStrDateTime12 = (datetime, isExcludeSeconds = false) => {

		let strFormat = !isExcludeSeconds ? "DD-MM-Y hh:mm:ss A" : "DD-MM-Y hh:mm A";

		let formatted = moment(datetime).tz("asia/jakarta").format(strFormat);
		return formatted;
	}

	/** Format datetime to String Date Day-Month-Year Format */
	formatDateTimeToStrDate = (datetime) => {
		let formatted = moment(datetime).tz("asia/jakarta").format("DD-MM-Y");
		return formatted;
	}

	/** Format datetime to String Time Hour:Minute:Second in 24 hours Format */
	formatDateTimeToStrTime24 = (datetime, isExcludeSeconds = false) => {
		let strFormat = !isExcludeSeconds ? "HH:mm:ss" : "HH:mm";
		let formatted = moment(datetime).tz("asia/jakarta").format(strFormat);
		return formatted;
	}

	/** Format datetime to String Time Hour:Minute:Second in 12 hours Format */
	formatDateTimeToStrTime12 = (datetime, isExcludeSeconds = false) => {
		let strFormat = !isExcludeSeconds ? "HH:mm:ss A" : "HH:mm A";
		let formatted = moment(datetime).tz("asia/jakarta").format(strFormat);
		return formatted;
	}

	/** Format datetime to String custom Format */
	formatDateTimeToStrFormat = (dateTime, strFormat) => {
		let formatted = moment(dateTime).tz("asia/jakarta").format(strFormat);
		return formatted;
	}

	isEmailValid = (value) => {
		let valid = false;
		if (!value) {
			valid = false;
		} else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			valid = true;
		}
		return valid;
	};
	
	isPhoneValid = (value) => {
		let valid = false;
		if (!value) {
			valid = false;
		} else if (/^[1-9]{6,13}/i.test(value)) {
			valid = true;
		} else if (value.length < 10) {
			valid = false;
		}
		return valid;
	};

	FORM_ORDER_MESSAGE_MARK = "Untuk pemesanan, silahkan lengkapi data";
	// FORM_ORDER_MESSAGE_MARK = "Tertera di Kartu Ucapan/Karangan Bunga Papan";

	buildInfo = () => {
		return "12/03/2025 11:31";
	}

	appVersion = () => {
		return "2.5.43";
	}

}

const instance = new Global();
Object.freeze(instance);

export default instance;
